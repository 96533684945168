import React from "react";

const SpeakingIcon = (props) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.409 0.564252C31.1381 0.048229 30.5026 -0.14956 29.9856 0.121354C29.4696 0.392268 29.2708 1.02979 29.5427 1.54476C30.961 4.24427 31.7108 7.28992 31.7108 10.352C31.7108 13.4141 30.961 16.4597 29.5427 19.1593C29.2708 19.6743 29.4696 20.3119 29.9856 20.5827C30.5003 20.8536 31.1377 20.6571 31.409 20.1398C32.9869 17.1395 33.8201 13.755 33.8201 10.352C33.8201 6.94905 32.9869 3.56449 31.409 0.564252Z"
        fill="white"
      />
      <path
        d="M27.6722 2.52223C27.4023 2.00621 26.7668 1.80849 26.2488 2.07933C25.7327 2.35024 25.534 2.98777 25.8059 3.50274C26.909 5.60283 27.492 7.97067 27.492 10.352C27.492 12.7334 26.909 15.1012 25.8059 17.2013C25.534 17.7163 25.7327 18.3538 26.2488 18.6247C26.7635 18.8956 27.4009 18.6991 27.6722 18.1818C28.9339 15.782 29.6013 13.0742 29.6013 10.352C29.6013 7.62987 28.9339 4.92199 27.6722 2.52223Z"
        fill="white"
      />
      <path
        d="M23.9359 4.4781C23.665 3.96208 23.0254 3.76535 22.5114 4.03626C21.9964 4.30717 21.7977 4.9447 22.0696 5.46072C22.8575 6.95824 23.2736 8.64946 23.2736 10.352C23.2736 12.0546 22.8575 13.7458 22.0696 15.2433C21.7977 15.7593 21.9964 16.3968 22.5114 16.6678C23.0293 16.9403 23.6668 16.7375 23.9359 16.2259C24.8824 14.4255 25.383 12.3944 25.383 10.352C25.383 8.30957 24.8824 6.27846 23.9359 4.4781Z"
        fill="white"
      />
      <path
        d="M12.7266 1.91455C8.0742 1.91455 4.28906 5.69968 4.28906 10.3521C4.28906 15.0044 8.0742 18.7896 12.7266 18.7896C17.3789 18.7896 21.1641 15.0044 21.1641 10.3521C21.1641 5.69968 17.3789 1.91455 12.7266 1.91455Z"
        fill="white"
      />
      <path
        d="M18.9894 18.7896C17.233 20.0974 15.0797 20.8989 12.7266 20.8989C10.3734 20.8989 8.22016 20.0974 6.46369 18.7896C3.87928 20.7138 2.17969 23.7649 2.17969 27.2271V32.8359C2.17969 34.5807 3.59902 36 5.34375 36H20.1094C21.8541 36 23.2734 34.5807 23.2734 32.8359V27.2271C23.2734 23.7649 21.5738 20.7138 18.9894 18.7896Z"
        fill="white"
      />
    </svg>
  );
};

export default SpeakingIcon;
