import React, { useEffect, useState } from 'react';
import { isDesktop } from "react-device-detect";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { checkUser, signOut } from '../../../../services/userService';
import ToggleDarkMode from '../../../ComponentShare/ToggleDarkMode';
import "./index.scss";

const HomeMenu = (props) => {
    // console.log("pops",props)
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "vi")
    const [userInfo, setUserInfo] = useState({})
    const token = localStorage.getItem('token')
    const changeLanguage = () => {
        const newLanguage = language === 'en' ? 'vi' : 'en';
        setLanguage(newLanguage)
        i18n.changeLanguage(newLanguage);
        localStorage.setItem('i18nextLng', newLanguage)

    };
    const logout = async () => {
        try {
            let result = await signOut()
            navigate('/auth/login', { replace: true })
        } catch (error) {
            alert("logout error!")
        }
    }

    // const handleVideoCourse = async ()=>{
    // let user = await getUser();
    // if(user?.isSuccess){
    // return window.open(`${process.env.REACT_APP_ONLINE_DOMAIN}/menu`,'_blank')
    // }else{
    //     return window.location.replace('/auth/login');
    // }

    // }
    const checkTokenExpried = async () => {
        try {
            const result = await checkUser()
            setUserInfo(result.data)
        } catch (error) {
            console.log('error', error)
            localStorage.removeItem("userInfo")
            localStorage.removeItem("token")
        }

    };
    useEffect(() => {
        checkTokenExpried()
    }, [])
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-scroll" id='homemenu'>
                <div className="container-fluid">
                    <div className='main-logo d-flex justify-content-center' >
                        <a className="navbar-brand " href="/">
                            <img
                                src="/images/logo.png"
                                className="mt-3"
                                height="85"
                                width="90"
                                alt="Logo"
                                loading="lazy"
                            />
                        </a>
                    </div>
                    <div className='navbarsItem' >
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsItemList" aria-controls="navbarsItemList" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse navbarsItemList" id="navbarsItemList">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href="/#aboutUs">{t(`home.header.menu.about`)}</a>
                                </li>
                                <li className="nav-item dropdown dropdown-center">
                                    <a className="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        IELTS
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="https://therealielts.vn">{t(`home.header.menu.ielts`)}</a></li>
                                        <li onClick={() => props.selecetTest("/shorttest")}><a className="dropdown-item" href="/#testlist">{t(`home.header.menu.inputtest`)}</a></li>
                                        <li onClick={() => props.selecetTest("/speaking")}><a className="dropdown-item" href="/#testlist">{t(`home.header.menu.speakingtest`)}</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        PTE
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="https://pte.ant-edu.ai/khoa-hoc-pte">Về bài thi PTE</a></li>
                                        <li onClick={() => props.selecetTest("/shorttest")}><a className="dropdown-item" href="/#testlist">{t(`home.header.menu.inputtest`)}</a></li>
                                        <li onClick={() => props.selecetTest("/speaking")}><a className="dropdown-item" href="/#testlist">{t(`home.header.menu.speakingtest`)}</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" target='__blank' href={`${process.env.REACT_APP_ONLINE_DOMAIN}/live`}>{t(`home.header.menu.onlineEvent`)}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" target='__blank' href={`${process.env.REACT_APP_ONLINE_DOMAIN}/${isDesktop ? "study-route" : "menu"}`} >{t(`home.header.menu.videoCourse`)}</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link " href="/#testlist"  >{t(`home.header.menu.inputtest`)}</a>
                                </li>


                            </ul>

                        </div>
                    </div>
                    <div className='language' >
                        {
                            userInfo.userName ?
                                <div className="box-info info-dropdown  justify-content-end ">
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img
                                                src={userInfo.avatar ? `${process.env.REACT_APP_BE_HOST}${userInfo.avatar}` : `${process.env.REACT_APP_DOMAIN}/images/user.png`}
                                                className="me-2"
                                                height="29"
                                                width="29"
                                                alt="flag"
                                                loading="lazy"
                                            />

                                            {userInfo?.firstName ? userInfo.firstName : userInfo?.displayName ? userInfo.displayName : userInfo?.userName}
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><a className="dropdown-item" href="/user/profile"><img src="/images/user-icon-blue.png" width={20} /> Profile</a></li>
                                            <li onClick={() => logout()}><a className="dropdown-item" href="#"><img src="/images/logout.png" width={20} /> Log out</a></li>
                                        </ul>
                                    </div>


                                </div>
                                :
                                <div className="box-info justify-content-md-center ">
                                    <ul className="navbar-nav">
                                        {
                                            isDesktop ? <>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/auth/login">{t(`home.header.menu.signIn`)}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/auth/register">{t(`home.header.menu.signUp`)}</a>
                                                </li>
                                            </>
                                                :
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/auth/login">{t(`home.header.menu.signIn`)}</a>
                                                </li>
                                        }

                                    </ul>
                                </div>

                        }
                        <div className="dropdown box-language">
                            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img
                                    src={language === 'vi' ? "/images/flag-vi.png" : "/images/flag-us.png"}
                                    className="me-2"
                                    height="29"
                                    width="29"
                                    alt="flag"
                                    loading="lazy"
                                />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-start">
                                <li onClick={() => changeLanguage()}>
                                    <img
                                        src={language === 'vi' ? "/images/flag-us.png" : "/images/flag-vi.png"}
                                        className="me-2"
                                        height="29"
                                        width="29"
                                        alt="flag"
                                        loading="lazy"
                                    />
                                    {isDesktop ? language === 'vi' ? "English" : "Việt Nam" : ""}

                                </li>
                            </ul>
                            <ToggleDarkMode />
                        </div>
                    </div>

                </div>
            </nav>

        </>
    )
}
export default HomeMenu;
