import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAnswerContext from "../../../context/useAnswerContext.js";
import RecordView from "../share/Recorder/index.js";
import Spinner from "../share/Sprinner";
import SubmitArea from "../share/SubmitArea/index.jsx";
import "./index.scss";

const Speaking = ({ nextSkill }) => {
  const darkMode = localStorage.getItem("darkMode") ?? "dark";

  const { setAnswer, setQuestions } = useAnswerContext();

  const [spData, setSpData] = useState([]);
  const [part, setPart] = useState(0);
  const [testType, setTestType] = useState();
  const [showSprinner, setShowSprinner] = useState(false);

  const token = localStorage.getItem("token");
  const location = useLocation();
  const uri = location.pathname;

  const nextPart = () => {
    setPart(part + 1);
  };

  const handleRecord = async ({ data, status }) => {
    if (!status) return alert("Lỗi upload recording!");
    setShowSprinner(true);
    try {
      let stgAnswer = localStorage.getItem("answer")
        ? new Map(Object.entries(JSON.parse(localStorage.getItem("answer"))))
        : new Map();
      stgAnswer.set(spData[part].id, {
        questionId: spData[part].id,
        answerContent: data,
      });

      setAnswer(stgAnswer);
      localStorage.setItem(
        "answer",
        JSON.stringify(Object.fromEntries(stgAnswer))
      );
      nextPart();
      // }
      setShowSprinner(false);
    } catch (error) {
      console.log("Elsa error", error);
      setShowSprinner(false);
    }
  };

  const getData = () => {
    const data = JSON.parse(localStorage.getItem("exam"));
    if (!data) return;
    setTestType(data?.testType);
    const newQuesion = data?.questionContent;
    // const newQuesion = JSON.parse(questionContent)
    let content = newQuesion?.languageSkillExams.filter(
      (e) => e.languageSkillType === "Speaking"
    );
    let newData = [];
    content[0].partitionExams[0]?.sectionExams?.map((e) => {
      e.groupQuestionExams?.map((i) => {
        i.questionExams?.map((j) => {
          newData.push(j);
        });
      });
    });
    setSpData(newData);
    setQuestions(newData);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="speaking" id="speaking">
        <div className="justify-content-center align-items-center ">
          <div className="container speaking-content">
            <div className="row">
              <div className="col-md-6 ">
                {/* <div className={`speaking-part ${darkMode =="dark"? " background-footer-dark":"background-ccc"}`}> */}
                <div className={`speaking-part`}>
                  {spData?.map((j, index) => {
                    let classBox = "box-speaking-part";
                    if (part === index)
                      classBox =
                        classBox +
                        ` active ${
                          darkMode == "dark"
                            ? "background-00F0FF"
                            : "background-white border-3060CF"
                        }`;
                    if (part > index)
                      classBox =
                        classBox +
                        ` passed ${darkMode == "dark" ? "" : " border-ccc"}`;
                    return (
                      <div className={classBox} key={j.id}>
                        <div className="box-content">
                          <div
                            className={`${
                              darkMode == "dark" ? "content" : "color-black"
                            }`}
                            dangerouslySetInnerHTML={{ __html: j.content }}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-6">
                {spData.map((e, i) => {
                  let time = i >= 1 ? 120 : 45;
                  if (i < part) {
                    return (
                      <div className="successfull">
                        <p>
                          {" "}
                          <img
                            src="/images/checked.png"
                            width={26}
                            height={26}
                          />
                          {`Your part ${
                            i + 1
                          }’s recorder is saved successfully !`}
                        </p>
                      </div>
                    );
                  } else if (i == part) {
                    return (
                      <RecordView
                        handleRecord={handleRecord}
                        nextPart={nextPart}
                        timeRecord={time}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <SubmitArea />

          {/* <div
            className={`ielts-wrapper-footer ${
              darkMode == "light"
                ? "background-white"
                : " background-footer-dark"
            }`}
          >
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-md-8 p-0 d-flex align-items-center h-100">
                  <ul className="list_question p-0 m-0">
                    {spData.map((e, i) => {
                      return (
                        <li
                          key={i}
                          className={
                            listAnswered.get(e.id)
                              ? darkMode == "light"
                                ? "answered border-3060CF background-3060CF"
                                : "answered border-00F0FF background-00F0FF"
                              : darkMode == "light"
                              ? "border-black "
                              : " border-white"
                          }
                        >
                          <a
                            className={
                              darkMode == "light"
                                ? listAnswered.get(e.id)
                                  ? "color-white"
                                  : "color-black"
                                : "color-white"
                            }
                            href={`#question-${e}`}
                          >
                            {i + 1}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-md-4">
                  {token && uri != "/speaking" && uri != "/speakingai" ? (
                    <>
                      <div
                        className={
                          darkMode == "light"
                            ? "row h-50 color-black"
                            : "row h-50 color-white"
                        }
                      >
                        <div
                          className={
                            darkMode == "light"
                              ? "d-flex align-items-end justify-content-center btn-next-skill-light"
                              : "d-flex align-items-end justify-content-center btn-next-skill"
                          }
                          onClick={() => nextSkill(3)}
                        >
                          Go to next skill{" "}
                          <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div
                        className={
                          darkMode == "light"
                            ? "row h-50 guide-question color-black "
                            : "row h-50 guide-question color-white"
                        }
                      >
                        <ul className="w-100 d-flex align-items-center justify-content-center m-0">
                          <li>
                            <div
                              className={
                                darkMode == "light"
                                  ? "circle border-black"
                                  : "circle border-white"
                              }
                            ></div>
                          </li>
                          <li>Unanswered</li>
                          <li>
                            <div
                              className={
                                darkMode == "light"
                                  ? "circle circle-answered border-3060CF background-3060CF"
                                  : "circle circle-answered border-00F0FF background-00F0FF"
                              }
                            ></div>
                          </li>
                          <li>Answered</li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <div
                      className={
                        darkMode == "light"
                          ? "row h-50 guide-question color-black "
                          : "row h-50 guide-question color-white"
                      }
                    >
                      <ul className="w-100 d-flex align-items-center justify-content-center m-0">
                        <li>
                          <div
                            className={
                              darkMode == "light"
                                ? "circle border-black"
                                : "circle border-white"
                            }
                          ></div>
                        </li>
                        <li>Unanswered</li>
                        <li>
                          <div
                            className={
                              darkMode == "light"
                                ? "circle circle-answered border-3060CF background-3060CF"
                                : "circle circle-answered border-00F0FF background-00F0FF"
                            }
                          ></div>
                        </li>
                        <li>Answered</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {showSprinner && <Spinner />}
    </>
  );
};

export default Speaking;
