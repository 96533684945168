import React from "react";
import { cn } from "../../../utils/helper/common.helper";
import "./cardTest.scss";
const CardTest = ({
  title,
  description,
  questions,
  maxScore,
  time,
  icon,
  className,
}) => {
  const darkMode = localStorage.getItem("darkMode") ?? "dark";
  return (
    <div
      className={cn(
        "relative rounded-[20px] pb-[5px] w-[300px]",
        darkMode == "light" ? "bg-white " : "bg-[#080E28]"
      )}
    >
      <div
        className={cn(
          "absolute rounded-[20px] z-2 inset-x-0 top-0 bottom-2 ",
          "shadow-md",
          "flex flex-col p-[25px_30px] space-y-3",
          darkMode == "light"
            ? "bg-white text-black"
            : "bg-[rgb(26,32,52)] text-white",
          className
        )}
      >
        <div className="flex justify-center items-center  bg-[#2BAED1]  rounded-[15px] size-[66px] shrink-0">
          {icon}
        </div>
        <div
          className={cn(
            "text-[20px] font-bold uppercase mb-0",
            darkMode == "light" ? "text-3060CF" : "text-[rgb(0,240,255)]"
          )}
        >
          {title}
        </div>
        <div className="grow">
          <div className="mt-0 italic line-clamp-3">{description}</div>
        </div>
        <div className="font-bold">
          Số câu hỏi: <span className="font-normal">{questions}</span>
        </div>
        <div className="font-bold">
          Điểm tối đa: <span className="font-normal">{maxScore}</span>
        </div>
        <div className="font-bold">
          Thời gian: <span className="font-normal">{time} phút</span>
        </div>
        <div className="">{/* button */}</div>
      </div>
      <div className="h-[366px] rounded-[24px] w-full bg-gradient-to-r from-[#0075cd] to-white"></div>
    </div>
  );
};

export default CardTest;
