import React, { useState } from "react";
import "./index.scss"

const SingleChoice = ({data,answerHandle})=>{   
    const answer = JSON.parse(localStorage.getItem('answer'))
    const answerwithID = answer? answer[data?.id] : null
    const getValueInAnswer = (id)=>{
        const dataSelect = data?.answerExams.filter(e=>e?.id == id)
        if(dataSelect.length >0) return dataSelect[0]
    }
    const valueDefault = answerwithID?.answerContent ? getValueInAnswer(answerwithID?.answerContent) : null
    return(
        <>
        <div className="question"  id={`question-${data?.id}`}>
            <div className="question-name" >
                <p dangerouslySetInnerHTML={{ __html: data?.content}}></p>
            </div>
            {
                data.materials?.map((tj)=>{
                    if(tj.materialType ==='mp3') return (
                            <div key={tj.id} className="question-audio" >
                                <audio className="w-100" src={process.env.REACT_APP_BE_HOST+tj.url}  controls="controls"  ></audio>
                            </div>
                    )
                })
            }
            <div className="list-answer">
                <ul>
                    {
                        data?.answerExams?.map((e)=>{
                            return(
                                <li key={e.id} >
                                    <div className="form-check">
                                        <input 
                                            className="form-check-input" 
                                            type="radio"  
                                            name={data?.id} id={`question${e.id}`}
                                            onClick={()=>answerHandle(data.id,e.id)} 
                                            defaultChecked={valueDefault?.id == e.id}
                                        />
                                        <label className="form-check-label" >
                                            {e.content}
                                        </label>
                                    </div>
                                </li>
                            )
                        })
                    }
                                        
                </ul>
            </div>
        </div>
        </>
    )
}
export default SingleChoice