import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExamNameCode from "../shared/ExamNameCode";

const MenuShortTest = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : {};
  const darkMode = localStorage.getItem("darkMode") ?? "dark";
  const data = JSON.parse(localStorage.getItem("exam"));
  const listSkill = data?.questionContent?.languageSkillExams;
  console.log("🚀 ~ MenuShortTest ~ listSkill:", listSkill);
  const handleClick = () => {
    const check = window.confirm(
      "Bạn chắc chắn sẽ thoát khỏi bài kiểm tra chứ!"
    );
    if (check) window.location.href = "/";
  };
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-scroll ${
          darkMode == "light" ? "background-3060CF" : ""
        }`}
        id="menuietls"
      >
        <div className="container-fluid">
          <div className="main-logo d-flex justify-content-center">
            <a className="navbar-brand " href="#" onClick={() => handleClick()}>
              <img
                src="/images/logo.png"
                className="me-2"
                height="63"
                width="73"
                alt="Logo"
                loading="lazy"
              />
            </a>
          </div>
          <div className="navbarsItem">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsItemList"
              aria-controls="navbarsItemList"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse navbarsItemList"
              id="navbarsItemList"
            >
              <ul className="navbar-nav">
                {listSkill?.map((e, i) => {
                  return (
                    <li
                      className={
                        props.active === e.languageSkillType.toLowerCase()
                          ? "nav-item active"
                          : "nav-item"
                      }
                      onClick={() => props.selectSkill(i)}
                    >
                      <a className="nav-link" href="#">
                        {e.languageSkillType}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <ExamNameCode
            examName={data?.questionContent?.examName}
            examCode={data?.questionContent?.examCode}
          />
        </div>
      </nav>
    </>
  );
};
export default MenuShortTest;
