import React, { memo } from "react";
import Countdown from "react-countdown";
// import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import { cn } from "../../../utils/helper/common.helper";
import "./index.scss";

const CountdownClock = (props) => {
  const skill = localStorage.getItem("skill");
  const exam = localStorage.getItem("exam");
  const subSkill = localStorage.getItem("subSkill");
  const darkMode = localStorage.getItem("darkMode");
  const getTime = () => {
    let ltime = 15 * 60 * 1000;

    switch (skill) {
      case "/fulltest":
        ltime = 185 * 60 * 1000;
        break;
      case "/shorttest":
        ltime = 30 * 60 * 1000;
        break;
      case "/speakingai":
        ltime = subSkill == "basic" ? 8 * 60 * 1000 : 8 * 60 * 1000; // tam thoi dẻ 8p hết
        break;
      default:
        break;
    }
    return ltime;
  };
  const time = JSON.parse(exam)?.limitTime
    ? JSON.parse(exam)?.limitTime * 60 * 1000
    : getTime();
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed
      return <Navigate to="/welldone" replace={true} />;
    } else {
      // Render a countdown
      return (
        <span>
          {String(hours).padStart(2, "0")} : {String(minutes).padStart(2, "0")}{" "}
          : {String(seconds).padStart(2, "0")}
        </span>
      );
    }
  };

  return (
    <div className="countdown">
      <div
        className={cn(
          "d-flex align-items-center box-countdown shadow-lg",
          darkMode == "light" ? "text-3060CF" : "text-white bg-black"
        )}
        style={{
          gap: "10px",
          padding: "8px 12px",
          borderRadius: "8px",
        }}
      >
        <div className="">
          <i className="fa-regular fa-clock fa-xl"></i>
        </div>
        <div
          className={cn(
            "count-time",
            darkMode == "light" ? "text-3060CF" : "text-white"
          )}
        >
          <Countdown date={Date.now() + time} renderer={renderer} />
        </div>
      </div>
    </div>
  );
};
export default memo(CountdownClock);
