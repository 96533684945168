import React, { useEffect, useState } from "react";
import Listening from "../PresenTations/Listening";
// import { ListenQuestion, ReadingQuestion, SpeQuestion, WritingQuestion } from "../../constants/fulltest";
import { isDesktop } from "react-device-detect";
import AnswerContextProvider from "../../context/AnswerProvider";
import HeaderMB from "../Layout/Menu/HeaderMB/Header";
import MenuFullTest from "../Layout/Menu/MenuFullTest";
import Reading from "../PresenTations/Reading";
import Speaking from "../PresenTations/Speaking";
import SpeakingMb from "../PresenTations/Speaking/speakingMb";
import Writing from "../PresenTations/Writing";

const Fulltest = () => {
  const [section, setSection] = useState(0);
  const data = JSON.parse(localStorage.getItem("exam"));
  const darkMode = localStorage.getItem("darkMode") ?? "dark";
  const content = data.questionContent;

  const handleNextSkill = (num) => {
    if (section >= content?.languageSkillExams.length - 1)
      return alert("Bạn đã hoàn thành các kỹ năng!");
    setSection(section + 1);
  };
  useEffect(() => {
    return () => {
      localStorage.removeItem("answer");
    };
  }, []);

  return (
    <>
      <AnswerContextProvider>
        {isDesktop ? (
          <MenuFullTest
            active={content.languageSkillExams[
              section
            ]?.languageSkillType.toLowerCase()}
            selectSkill={setSection}
          />
        ) : (
          <HeaderMB
            content={content.languageSkillExams[section]?.languageSkillType}
            nextSkill={handleNextSkill}
            section={section}
          />
        )}
        <div
          className="content-ielts-wrapper"
          style={{
            backgroundImage: isDesktop
              ? darkMode == "dark"
                ? `url("/images/${content.languageSkillExams[
                    section
                  ]?.languageSkillType.toLowerCase()}-bg.png")`
                : `url("/images/light/bg-test.png")`
              : "",
          }}
        >
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "listening" && (
            <Listening nextSkill={handleNextSkill} />
          )}
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "reading" && (
            <Reading nextSkill={handleNextSkill} />
          )}
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "speaking" &&
            isDesktop && <Speaking nextSkill={handleNextSkill} />}
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "speaking" &&
            !isDesktop && <SpeakingMb nextSkill={handleNextSkill} />}
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "writing" && (
            <Writing nextSkill={handleNextSkill} />
          )}
        </div>
      </AnswerContextProvider>
    </>
  );
};
export default Fulltest;
