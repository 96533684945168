import React, { useEffect, useState } from 'react'
import './welcome.scss'
import HomeMenu from '../Layout/Menu/Home'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isDesktop} from "react-device-detect";
import WelcomeMb from './WelcomeMb'
import WelcomeLight from './WelcomeLight'
import CheckMedia from '../GetMedia/CheckMedia'

const Welcome = () => {
    const { t } = useTranslation();
    const [username,setUsername] = useState()
    const navigate = useNavigate()
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )
    const skill = localStorage.getItem("skill")
    const subSkill = localStorage.getItem("subSkill")
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const handleClick = ()=>{

        if(userInfo?.userName || username) {
            if(username){
                userInfo.userName = username;
                localStorage.setItem("userInfo",JSON.stringify(userInfo))
            }            
            if(skill==='/speakingai' && subSkill !=='' ) return navigate('/speakingai')
            return navigate('/listening')
        }
        if(!username) return alert("Username Requirements")
        return navigate('/listening')
    }
    return (
        <>   
            {
                !isDesktop ? <WelcomeMb/> :
                <>
                    {
                        darkMode =="dark" &&
                    
                        <div className='container-fluid welcome p-0' id="welcome">
                            <HomeMenu/>
                            <div className='intro  d-flex align-items-center '>
                                <div className='container'>
                                    <div className='row d-flex justify-content-center' >
                                        <div className='col-md-7' >
                                            {
                                                userInfo.userName ? 
                                                <>
                                                    <p className='h2 title'>Hello {userInfo.userName}, </p>
                                                    <p className='detail'>{t(`welcome.detail`)}</p>
                                                    <div className='btn-group' >
                                                        <CheckMedia/>
                                                        <div className="btn-get-started" onClick={()=>handleClick()}>
                                                            <p>GET STARTED</p>
                                                        </div>
                                                    </div>
                                                    
                                                </>
                                                :
                                                <>
                                                    {/* <p className='h2 title'>Welcome to <span>Ant Edu’s</span>  Input Test!</p> */}
                                                    <p className='h2 title' dangerouslySetInnerHTML={{__html:t(`welcome.hello`) }}></p>
                                                    <p className='detail'>{t(`welcome.detail`)}</p> 
                                                    {/* <CheckMedia/>                                                    */}
                                                    <div className="input-group mb-3 box-username ">
                                                        <input type="text" className="form-control" placeholder="Recipient's username" aria-label="Enter your name here" aria-describedby="basic-addon2" onChange={event => setUsername(event.target.value)}/>
                                                        <div className="input-group-append">
                                                            <div className="btn-get-started" onClick={()=>handleClick()}>
                                                                <p>GET STARTED</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            
                                            <div className='rocket d-flex justify-content-center'>
                                                <img src='./images/rocket.gif' width={130}/>
                                            </div>                               
                                        </div>
                                    </div>
                                </div>
                            </div>                
                        </div>
                    }
                    {
                        darkMode =="light" && <WelcomeLight />
                    }
                </>
            }          
        </>
    )
}
export default Welcome