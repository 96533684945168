import { useContext } from "react";

import { createContext } from "react";

export const AnswerContextInit = {
  answer: [],
  questions: [],
  setAnswer: () => {},
  setQuestions: () => {},
};
export const AnswerContext = createContext(AnswerContextInit);

export default function useAnswerContext() {
  return useContext(AnswerContext);
}
