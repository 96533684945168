import React, { useEffect, useState } from "react";
import "./index.scss"

const QuestionSelectAnswer = ({data,handleAnswer})=>{
    const [selected,setSelected] = useState()

    const answer = JSON.parse(localStorage.getItem('answer'))
    const answerwithID = answer ? answer[data.id] : null
    
    const handleOnclick = (id,key,value)=>{
        setSelected(value);
        handleAnswer(id,key)
    }
    const getValueInAnswer = (id)=>{
        const dataSelect = data?.answerExams.filter(e=>e?.id == id)
        if(dataSelect.length >0) return dataSelect[0].content
    }
    const valueDefault = answerwithID?.answerContent ? getValueInAnswer(answerwithID?.answerContent) : null
 
    return(
        <>
        <div className="question question-select-answer"  id={`question-${data?.id}`}>
            <div className="row">
                <div className="col-md-6">
                    <div className="question-name" >
                        <p dangerouslySetInnerHTML={{ __html: `${data.content}`}}></p>
                    </div>
                    {
                        data.materials?.map((tj)=>{
                            if(tj.materialType ==='mp3') return (
                                    <div key={tj.id} className="question-audio" >
                                        <audio className="w-100" src={process.env.REACT_APP_BE_HOST+tj.url}  controls="controls"  ></audio>
                                    </div>
                            )
                        })
                    }
                </div>
                <div className="col-md-6">
                    <div className="list-answer">
                        <div className="dropdown">
                            <div className="btn-group">
                                <button type="button" className="btn btn-default btn-select" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selected ? selected : valueDefault?? "" }<i className="fa fa-angle-down" aria-hidden="true"></i>
                                </button>
                                <ul className="dropdown-menu">
                                    {
                                        data.answerExams?.map((e)=>{
                                            // console.log(e)
                                            return(
                                                <li className="dropdown-item" key={e.id} onClick={()=>handleOnclick(data?.id,e.id,e.content)}>{e.content}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default QuestionSelectAnswer