import React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../../../utils/helper/common.helper";
import AdvanceFormIcon from "../../Icons/AdvanceFormIcon";
import BasicFormIcon from "../../Icons/BasicFormIcon";
import SpeakingIcon from "../../Icons/SpeakingIcon";
import CardTest from "./CardTest";
import "./sectionTest.scss";

const SectionTest = ({ selecetTest, setSelecetTest, handleClickTest }) => {
  const { t } = useTranslation();
  const darkMode = localStorage.getItem("darkMode") ?? "dark";
  const testList = [
    {
      id: 1,
      icon: <BasicFormIcon />,
      title: "Basic Test",
      description: "Bài test ngắn 4 kỹ năng dành cho người mới hoặc mất gốc",
      questions: 50,
      maxScore: 50,
      time: 15,
      hasLink: true,
      subSkill: "",
      skill: "/shorttest",
    },
    {
      id: 2,
      icon: <AdvanceFormIcon />,
      title: "Advance Test",
      description: "Bài test chuẩn 4 kỹ năng dành cho người đã từng học IELTS",
      questions: 100,
      maxScore: 100,
      time: 150,
      hasLink: true,
      subSkill: "",
      skill: "/fulltest",
    },
    {
      id: 3,
      icon: <SpeakingIcon />,
      title: "AI Basic Speaking Test",
      description: "Bài test Speaking cho người mới bắt đầu",
      questions: 2,
      maxScore: 10,
      time: 8,
      hasLink: true,
      subSkill: "basic",
      skill: "/speakingai",
    },
    {
      id: 4,
      icon: <SpeakingIcon />,
      title: "AI Advance Speaking Test",
      description: "Bài test Speaking cho người đã từng học IELTS",
      questions: 1,
      maxScore: 10,
      time: 8,
      hasLink: true,
      skill: "/speakingai",
      subSkill: "advance",
    },
  ];

  return (
    <section
      className={cn(
        "d-flex align-items-center justify-content-center",
        darkMode == "light" ? "section-test-light" : "section-test"
      )}
      style={{ paddingTop: "100px" }}
      id="testlist"
    >
      <div className="small-container px-2 m-0">
        <h2
          className={cn(
            "text-[clamp(24px,5vw,40px)] font-bold text-center mb-10 drop-shadow-[0_0_20px_rgba(0,240,255,0.7)]",
            darkMode == "light" ? "text-black" : "text-white"
          )}
        >
          Luyện tập và kiểm tra trình độ
        </h2>
        <div className="flex flex-wrap justify-center gap-4">
          {testList.map((item, index) => (
            <div
              key={item.id}
              className={cn(index % 2 !== 0 && "mt-10")}
              onClick={() => {
                handleClickTest({ subSkill: item.subSkill, skill: item.skill });
              }}
            >
              <CardTest
                {...item}
                className={cn(
                  item.hasLink &&
                    "cursor-pointer transition-all hover:scale-105 hover:shadow-md hover:shadow-[rgb(0,240,255)]"
                )}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectionTest;
