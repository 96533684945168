import React from "react";
// import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { cn } from "../../../../utils/helper/common.helper";
import Countdownclock from "../../Countdownclock";
import CarouselQuestionPallete from "./Carousel/CarouselQuestionPallete";
import "./styles.scss";

const SubmitArea = ({ wrapClassName }) => {
  const darkMode = localStorage.getItem("darkMode") ?? "dark";
  // const { t, i18n } = useTranslation();
  // const navigate = useNavigate();
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : {};

  return (
    <>
      <div
        className={cn(
          "ielts-wrapper-footer",
          darkMode == "light" ? "background-white" : "background-footer-dark",
          wrapClassName
        )}
      >
        <div className="container h-100">
          <div className="row h-100 d-flex flex-column" style={{ gap: "12px" }}>
            <div className="col-md-7 h-100">
              <CarouselQuestionPallete />
            </div>

            <div className="col-md-5 h-100">
              <div className="d-flex h-100 m-0 p-0" style={{ gap: "12px" }}>
                <div className="d-flex align-items-center p-0">
                  <Countdownclock />
                </div>

                <div className="d-flex align-items-center justify-content-center flex-grow-1">
                  <button
                    className="btn-submit"
                    data-bs-toggle="modal"
                    data-bs-target="#submission"
                    style={{
                      padding: "8px 12px",
                      borderRadius: "8px",
                      backgroundColor: "#00F0FF",
                      border: "none",
                    }}
                  >
                    <p className="m-0">
                      <i className="fa-solid fa-location-arrow"></i>Submit
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL SUBMIT */}
      <div
        className="modal fade "
        id="submission"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title">
                <b>
                  {" "}
                  {userInfo?.firstName
                    ? userInfo.firstName
                    : userInfo?.displayName
                    ? userInfo.displayName
                    : userInfo?.userName}
                </b>
              </p>
              <button
                type="button"
                className="btn-close btn-close-black"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Are you sure with this submission?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-submission btn-submission-no"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <b>No</b>
              </button>
              <button
                type="button"
                className="btn btn-submission btn-submission-yes"
                onClick={() => (window.location.href = "/welldone")}
              >
                <b>Yes</b>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* END MODAL SUBMIT */}
    </>
  );
};

export default SubmitArea;
