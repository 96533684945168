import useEmblaCarousel from "embla-carousel-react";
import React, { useCallback, useEffect, useState } from "react";
import useAnswerContext from "../../../../../context/useAnswerContext";
import { cn } from "../../../../../utils/helper/common.helper";
import ArrowLeft from "../../../../Icons/ArrowLeft";
import ArrowRight from "../../../../Icons/ArrowRight";
import "./styles.scss";

export function CarouselQuestionPallete() {
  const { questions, answer } = useAnswerContext();
  const darkMode = localStorage.getItem("darkMode") ?? "dark";

  const [emblaRef, emblaApi] = useEmblaCarousel({
    dragFree: true,
  });
  const [selectedSnap, setSelectedSnap] = useState(0);

  const updateScrollSnapState = useCallback((emblaApi) => {
    setSelectedSnap(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    updateScrollSnapState(emblaApi);
    emblaApi.on("select", updateScrollSnapState);
    emblaApi.on("reInit", updateScrollSnapState);
  }, [emblaApi, updateScrollSnapState]);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollTo(selectedSnap - 3, true);
  }, [emblaApi, selectedSnap]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollTo(selectedSnap + 3, true);
  }, [emblaApi, selectedSnap]);

  return (
    <div className="" style={{ height: "100%", padding: "16px 0" }}>
      <p
        className={cn(
          "mb-1",
          darkMode == "light" ? "color-black" : "color-white"
        )}
        style={{ fontWeight: "bold" }}
      >
        Questions Pallete
      </p>
      <div className="embla" ref={emblaRef}>
        <div className="embla__container questions-pallete">
          {questions.map((e, i) => (
            <a
              key={i}
              className={cn(
                "embla__slide question-pallete-item",
                answer.get(e)
                  ? darkMode == "light"
                    ? "answered border-3060CF background-3060CF"
                    : "answered border-2BAED1 background-2BAED1"
                  : darkMode == "light"
                  ? "border-black "
                  : " border-white",

                darkMode == "light"
                  ? answer.get(e)
                    ? "color-white"
                    : "color-black"
                  : "color-white"
              )}
              style={{
                textDecoration: "none",
                fontWeight: "600",
                fontSize: "14px",
              }}
              href={`#question-${e}`}
            >
              {i + 1}
            </a>
          ))}
        </div>
      </div>
      <div className="unanswered-questions mt-2 flex justify-content-between">
        <div
          className={cn(
            "guide-question",
            darkMode == "light" ? "color-black" : "color-white"
          )}
          style={{ fontSize: "12px" }}
        >
          <ul className="w-100 d-flex  m-0" style={{ paddingLeft: "0px" }}>
            <li>
              <div
                className={
                  darkMode == "light"
                    ? "circle border-black"
                    : "circle border-white"
                }
              ></div>
            </li>
            <li>Unanswered</li>
            <li>
              <div
                className={
                  darkMode == "light"
                    ? "circle circle-answered border-3060CF background-3060CF"
                    : "circle circle-answered border-2BAED1 background-2BAED1"
                }
              ></div>
            </li>
            <li>Answered</li>
          </ul>
        </div>
        <div className="d-flex gap-2" style={{ fontSize: "14px" }}>
          <div
            type="button"
            onClick={onPrevButtonClick}
            style={{ cursor: "pointer" }}
          >
            <ArrowLeft className="hover-color-3060cf hover-bg-white" />
          </div>
          <div
            type="button"
            onClick={onNextButtonClick}
            style={{ cursor: "pointer" }}
          >
            <ArrowRight className="hover-color-3060cf hover-bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselQuestionPallete;
