import React, { useEffect, useState } from 'react';
import { isDesktop } from "react-device-detect";
import { useTranslation } from 'react-i18next';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { checkUser } from '../../services/userService';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import HomeMenu from '../Layout/Menu/Home';
import Antedu from './antedu';
import './index.scss';
import HomeLight from './indexLight';
import HomePageMb from './indexMb';
import SectionTest from './SectionTest';

const HomePage = () => {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "vi"
    const darkMode = localStorage.getItem('darkMode') ?? "dark"
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [selecetTest, setSelecetTest] = useState(searchParams.get("test") == "speakingai" ? "/speakingai" : "/shorttest");

    const handleClickTest = async ({ subSkill, skill }) => {
        if (skill === "/speakingai") {
            try {
                await checkUser()
            } catch (error) {
                navigate("/auth/login")
                return
            }

        }
        localStorage.setItem("skill", skill)
        localStorage.setItem("subSkill", subSkill)
        localStorage.removeItem("exam")
        localStorage.removeItem("answer")
        navigate("/welcome")
    }
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    useEffect(() => {
        if (document.referrer) localStorage.setItem("referrer", document.referrer)
        const browserId = localStorage.getItem("browserId")
        if (!browserId) {
            localStorage.setItem("browserId", uuidv4());
        }
    }, [])

    return (
        <>
            {
                isDesktop ?
                    <>
                        {
                            darkMode == "dark" &&
                            <>
                                <div className='container-fluid homepage' id="homepage">
                                    {/* {
                                        searchParams.get("test") == "speakingai" ?
                                            <AiSpeakingModal handleClickTest={handleClickTest} selecetTest={setSelecetTest} />
                                            :
                                            // <HomeModal handleClickTest={handleClickTest} selecetTest={setSelecetTest} />
                                            <></>
                                    } */}
                                    <HomeMenu selecetTest={setSelecetTest} />
                                    <Header />
                                    <section className='edtech ' id="edtech">
                                        <div className='container'>
                                            <div className='header-edtech d-flex align-items-center justify-content-center'>
                                                <h3 className=''>AI IN EDTECH</h3>
                                            </div>
                                            <div className='content-edtech'>
                                                <div className='row p-0 m-0'>
                                                    <div className='col-md-5 content-left'>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <p className="card-title h5" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech1.title`) }}></p>
                                                                <p className="card-text" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech1.detail`) }}></p>
                                                            </div>
                                                            <img src="./images/edtech-left.png" className="card-img-bottom" alt="edtech" />
                                                        </div>

                                                    </div>
                                                    <div className='col-md-7 content-right'>
                                                        <div className="card  card1">
                                                            <div className="row g-0">
                                                                <div className="col-md-8">
                                                                    <div className="card-body">
                                                                        <p className="card-title h5" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech2.title`) }}></p>
                                                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech2.detail`) }}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <img src="./images/edtech-right1.png" className="img-fluid" alt="edtech" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card  card2">
                                                            <div className="row g-0">
                                                                <div className="col-md-8">
                                                                    <div className="card-body">
                                                                        <p className="card-title h5" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech3.title`) }}></p>
                                                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech3.detail`) }}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <img src="./images/edtech-right2.png" className="img-fluid" alt="edtech" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='footer-edtech d-flex justify-content-center'>
                                                <img src="./images/arrow-down-exp.gif" width={54} className="card-img-bottom" alt="edtech" />
                                            </div>
                                        </div>
                                    </section>

                                    <Antedu />

                                    <SectionTest selecetTest={selecetTest} setSelecetTest={setSelecetTest} handleClickTest={handleClickTest} />



                                    <section className='ielts-list d-flex align-items-center justify-content-center' id="ieltsList">
                                        <div className='container m-0 p-0'>
                                            <div className='ielts-list-left'>
                                                <div className='left-head'>
                                                    <h3 className='title'>{t(`home.ieltsList.title`)}</h3>
                                                </div>
                                                <div className='left-content'>
                                                    <div className='list-core'>
                                                        <div className='box-core'>
                                                            <p className='title-score toggle-collapse' data-bs-toggle="collapse" data-bs-target="#40">4.0+ IELTS</p>
                                                            <div id="40" className="collapse">
                                                                <p className='item-score'>{t(`home.ieltsList.40.a`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.40.b`)}</p>
                                                            </div>
                                                        </div>
                                                        <div className='box-core'>
                                                            <p className='title-score toggle-collapse' data-bs-toggle="collapse" data-bs-target="#55">5.5+ IELTS</p>
                                                            <div id="55" className="collapse">
                                                                <p className='item-score'>{t(`home.ieltsList.55.a`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.55.b`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.55.c`)}</p>
                                                            </div>
                                                        </div>
                                                        <div className='box-core'>
                                                            <p className='title-score toggle-collapse' data-bs-toggle="collapse" data-bs-target="#65">6.5+ IELTS</p>
                                                            <div id="65" className="collapse">
                                                                <p className='item-score'>{t(`home.ieltsList.65.a`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.65.b`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.65.c`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.65.d`)}</p>
                                                            </div>
                                                        </div>
                                                        <div className='box-core'>
                                                            <p className='title-score toggle-collapse' data-bs-toggle="collapse" data-bs-target="#75">7.5+ IELTS</p>
                                                            <div id="75" className="collapse">
                                                                <p className='item-score'>{t(`home.ieltsList.75.a`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.75.b`)}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ielts-list-right'>
                                                <img src="./images/AI2.png" className="card-img-bottom" alt="antedu" />
                                            </div>
                                        </div>
                                    </section>


                                    <section className='about-us ' id="aboutUs">
                                        <div className='container'>
                                            <div className='header-about-us'>
                                                <h3 className='text-center'>{t(`home.aboutUs.title`)}</h3>
                                            </div>
                                            <div className="container content-about-us">
                                                <div className="row mx-auto my-auto justify-content-center">
                                                    <Carousel
                                                        responsive={responsive}
                                                        autoPlay={true}
                                                        autoPlaySpeed={5000}
                                                        itemclassName="image-item"
                                                    >
                                                        <div className="card">
                                                            <a href='https://therealielts.vn/ve-phuong-phap-hoc/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide1.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.technology`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/ve-ant-edu/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide2.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.human`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://therealielts.vn/ve-phuong-phap-hoc/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide3.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.exclustive`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/san-pham/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide4.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.commitment`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/ve-ant-edu/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide5.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.comnunity`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/ve-ant-edu/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide6.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.product`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/ve-ant-edu/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide7.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.event`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://khaothi.ant-edu.ai/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide8.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.exam`)}</div>
                                                            </a>
                                                        </div>
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </div>
                                <Footer />
                            </>
                        }
                        {
                            darkMode == "light" && <HomeLight />
                        }
                    </>
                    :
                    <HomePageMb />
            }
        </>
    )
}
export default HomePage
