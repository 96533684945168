import React, { useEffect, useRef, useState } from "react";
import { uploadFile } from "../../services/ieltsService";
import Recorder from "../PresenTations/share/Recorder/Recoder";
import "./index.scss";

const CheckMedia = () => {
    const [audioInput, setAudioInput] = useState(undefined);
    const [audioInputDevice, setAudioInputDevice] = useState([]);
    const [status, setStatus] = useState(false)
    const [result, setResult] = useState([])
    const canvasRef = useRef(null)
    const [counter, setCounter] = useState(0);
    const startStream = async () => {
        const audioSource = audioInput || undefined;
        let constraints = {
            audio: true,
            video: false
        };
        if (audioInput) {
            constraints['audio'] = { deviceId: audioSource ? { exact: audioSource } : undefined };
        }
        // console.log("---constraints",constraints)
        navigator.mediaDevices.getUserMedia(constraints).then(gotStream).catch(handleError);
    };
    const handleError = (error) => {
        alert("Mic issue!")
        console.log("-----------error", error)
    }
    const gotStream = (stream) => {
        // setMediaStream(stream)
        getDevices()
    }

    const getDevices = () => {
        navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(handleError);
    }
    const gotDevices = (deviceInfos) => {
        let audioDevice = []
        for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i];
            if (deviceInfo.deviceId == '') {
                continue;
            }
            if (deviceInfo.kind === 'audioinput') {
                audioDevice.push(deviceInfo)
            } else if (deviceInfo.kind === 'audiooutput') {
            } else {
                console.log('Some other kind of source/device: ', deviceInfo);
            }
        }
        setAudioInputDevice(audioDevice)
        startRecording()
    }

    const selectDevice = (event) => {
        // console.log("select- value",event.target.value)
        localStorage.setItem("audioinput", event.target.value)
        setAudioInput(event.target.value)
        startStream()
    }

    const visualize = (analyser) => {
        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext("2d");
        let WIDTH = canvas.width;
        let HEIGHT = canvas.height;

        if (!analyser) return;
        analyser.fftSize = 2048;
        let bufferLength = analyser.fftSize;
        let dataArray = new Uint8Array(bufferLength);
        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
        let draw = function () {

            let drawVisual = requestAnimationFrame(draw);

            analyser.getByteTimeDomainData(dataArray);

            canvasCtx.fillStyle = 'rgba(0, 240, 255, 0.15)';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = '#FFFFFF';

            canvasCtx.beginPath();

            let sliceWidth = WIDTH * 1.0 / bufferLength;

            let x = 0;

            for (let i = 0; i < bufferLength; i++) {

                let v = dataArray[i] / 128.0;
                let y = v * HEIGHT / 2;

                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
        };
        draw();
    }
    const startRecording = () => {
        Recorder.start((event) => {
            console.log("start", event)
            if (event.stream && event.analyser != null) {
                console.log("is recording")
                setCounter(0)
                setStatus(true)
                setResult([])
                visualize(event.analyser)
            } else {
                alert("Không connect được micro, hãy kiểm tra lại và reload lại trang!");
            }
        });
    }

    const stopRecording = () => {
        Recorder.stop(async (event) => {
            console.log("recorder success: ", event);
            visualize(null)
            let file = new File([event.blob], `Speaking-${(Math.random() * 1000).toString()}.mp3`, { type: "audio/mp3" });
            //   const blobUrl = URL.createObjectURL(event.blob);
            //   document.getElementById("audioBlob").src = blobUrl;
            try {
                const res = await uploadFile(file, 'media')
                setStatus(false)
                // let newResult = [...result]
                // newResult.push(res.data)
                // setResult(newResult)
                setResult([res.data])
            } catch (error) {
                alert("update file issue!")
                console.log('Upload file error', error);
            }
        });
    }
    useEffect(() => {
        let intervalId;
        if (status) {
            intervalId = setInterval(() => {
                setCounter((counter) => counter + 1);
                if (status && counter >= 10) {
                    console.log("stop")
                    stopRecording();
                }
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [status, counter]);
    return (
        <>
            <div className="btn-get-started" data-bs-toggle="modal" data-bs-target="#CheckMedia">
                <p>CHECK MIC</p>
            </div>
            <div className="modal fade" id="CheckMedia" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="CheckMediaLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="CheckMediaLabel">CHECK MIC</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => stopRecording()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="box-visualize">
                                <canvas ref={canvasRef} id="visualizeMic" className="w-100"></canvas>
                                {
                                    status && <select onChange={(e) => selectDevice(e)} >
                                        {
                                            audioInputDevice.map((e) => {
                                                return (
                                                    <option value={e.deviceId}>{e.label}</option>
                                                )
                                            })
                                        }
                                    </select>

                                }
                            </div>
                            <div className="box-actions">
                                {
                                    status ?
                                        <div className="btn-start-stop" onClick={() => stopRecording()}><svg viewBox="0 0 448 512"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"></path></svg></div>
                                        :
                                        <div className="btn-start-stop" onClick={() => startStream()}><svg aria-hidden="true" height="20" width="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6 5.468v13.064a.11.11 0 0 0 .019.066c.015.025.05.062.11.096.126.069.286.078.429.005l12.802-6.532a.283.283 0 0 0 .12-.099.13.13 0 0 0 0-.136.283.283 0 0 0-.12-.099L6.558 5.301a.456.456 0 0 0-.429.005.303.303 0 0 0-.11.096.11.11 0 0 0-.019.066Zm-1.5 0v13.064c0 1.305 1.508 2.132 2.74 1.503l12.802-6.532c1.277-.652 1.277-2.354 0-3.006L7.24 3.965c-1.232-.629-2.74.198-2.74 1.503Z"></path></svg></div>
                                }


                            </div>
                            <div className="box-result">
                                {
                                    result.map((e) => {
                                        return (
                                            <audio controls src={process.env.REACT_APP_BE_HOST + e}></audio>
                                        )
                                    })
                                }
                                {/* <audio id="audioBlob" controls ></audio> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CheckMedia
