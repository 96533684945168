import React from "react";

const ExamNameCode = ({ examName, examCode }) => {
  return (
    <div
      className="action-ielts d-flex justify-content-center font-bold"
      style={{
        gap: "10px",
      }}
    >
      <p className="mb-0">{examName}</p>
      <p className="mb-0"> - </p>
      <p className="mb-0">{examCode}</p>
    </div>
  );
};

export default ExamNameCode;
