import React, { useState } from "react";
import GrammarAndReading from "../PresenTations/GrammarAndReading";
import Listening from "../PresenTations/Listening";
import Speaking from "../PresenTations/Speaking";
import Writing from "../PresenTations/Writing";
// import { GraAndReadingQuestionShorttest, ListenQuestionShorttest, SpeQuestionShorttest, WritingQuestionShorttest } from "../../constants/quiz";
import { isDesktop } from "react-device-detect";
import AnswerContextProvider from "../../context/AnswerProvider";
import HeaderMB from "../Layout/Menu/HeaderMB/Header";
import MenuShortTest from "../Layout/Menu/MenuShortTest";
import SpeakingMb from "../PresenTations/Speaking/speakingMb";

const Shorttest = () => {
  const [section, setSection] = useState(0);
  const data = JSON.parse(localStorage.getItem("exam"));
  const darkMode = localStorage.getItem("darkMode") ?? "dark";

  const content = data.questionContent;
  const handleNextSkill = (num) => {
    if (section >= content?.languageSkillExams.length - 1)
      return alert("Bạn đã hoàn thành các kỹ năng!");
    setSection(section + 1);
  };

  return (
    <div className={!isDesktop ? "short-test-mb" : ""}>
      <AnswerContextProvider>
        {isDesktop ? (
          <MenuShortTest
            active={content.languageSkillExams[
              section
            ]?.languageSkillType.toLowerCase()}
            selectSkill={setSection}
          />
        ) : (
          <HeaderMB
            content={content.languageSkillExams[section]?.languageSkillType}
            nextSkill={handleNextSkill}
            section={section}
          />
        )}

        <div
          className="content-ielts-wrapper"
          style={{
            backgroundImage: isDesktop
              ? isDesktop
                ? darkMode == "light"
                  ? `url("/images/light/bg-test.png")`
                  : `url("/images/${content.languageSkillExams[
                      section
                    ]?.languageSkillType.toLowerCase()}-bg.png")`
                : ""
              : "",
          }}
        >
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "listening" && (
            <Listening nextSkill={handleNextSkill} />
          )}
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "reading" && (
            <GrammarAndReading nextSkill={handleNextSkill} />
          )}
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "speaking" &&
            isDesktop && <Speaking nextSkill={handleNextSkill} />}
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "speaking" &&
            !isDesktop && <SpeakingMb nextSkill={handleNextSkill} />}
          {content.languageSkillExams[
            section
          ]?.languageSkillType.toLowerCase() === "writing" && (
            <Writing nextSkill={handleNextSkill} />
          )}
        </div>
      </AnswerContextProvider>
    </div>
  );
};
export default Shorttest;
