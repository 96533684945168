import { useState } from "react";
import { AnswerContext } from "./useAnswerContext";

const AnswerContextProvider = ({ children }) => {
  const [answer, setAnswer] = useState(
    localStorage.getItem("answer")
      ? new Map(Object.entries(JSON.parse(localStorage.getItem("answer"))))
      : new Map()
  );
  const [questions, setQuestions] = useState([]);

  return (
    <AnswerContext.Provider
      value={{ answer, setAnswer, questions, setQuestions }}
    >
      {children}
    </AnswerContext.Provider>
  );
};

export default AnswerContextProvider;
