import React, { useState } from "react";
import "./index.scss"

const WriteAnswer = ({data,handleAnswer})=>{
    const darkMode = localStorage.getItem('darkMode') ?? "dark"
    const answer = JSON.parse(localStorage.getItem('answer'))
    const answerwithID = answer? answer[data?.id] : null
    return(
        <>
        <div className="question question-listen-answer write-answer"  id={`question-${data?.id}`} >
            <div className="row">
                <div className="question-name" >
                    <p dangerouslySetInnerHTML={{ __html: `${data?.content}`}}></p>
                </div>
                {
                    data.audio && <div className="question-audio" >
                                            <audio src={data.audio}  controls="controls"  ></audio>
                                        </div>
                }                
                {
                    data.img &&   <div className="question-img" >
                                            <img src={data.img}  width={400} />
                                        </div>
                }
                <div className="row">
                    <div className="col-md-6">
                        <div className="list-answer">
                            <div className="input-group">
                                <input 
                                    type="text" 
                                    className={darkMode =="dark"?"form-control color-white placeholder-dark border-bottom-white":"form-control color-black border-bottom-black placeholder-light"}
                                    placeholder="Answer here..." 
                                    onChange={(e)=>handleAnswer(data.id,e.target.value)}  
                                    defaultValue={answerwithID?.answerContent}
                                 />
                            </div>                            
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
        </>
    )
}
export default WriteAnswer