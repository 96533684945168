import React, { useEffect, useState } from "react";
import useAnswerContext from "../../../context/useAnswerContext";
import WriteAnswer from "../share/ListenWriteAnswer";
import MultipleChoice from "../share/MultipleChoiceAnswer";
import QuestionSelectAnswer from "../share/QuestionSelectAnswer";
import SingleChoice from "../share/SingleChoice";
import SubmitArea from "../share/SubmitArea";
import "./index.scss";

const Listening = ({ nextSkill }) => {
  const [data, setData] = useState();

  const skill = localStorage.getItem("skill");

  const { setAnswer, setQuestions } = useAnswerContext();

  const darkMode = localStorage.getItem("darkMode") ?? "dark";

  const handleAnswer = (questionId, value) => {
    let stgAnswer = localStorage.getItem("answer")
      ? new Map(Object.entries(JSON.parse(localStorage.getItem("answer"))))
      : new Map();
    stgAnswer.set(questionId, {
      questionId,
      answerContent: value,
    });
    setAnswer(stgAnswer);
    localStorage.setItem(
      "answer",
      JSON.stringify(Object.fromEntries(stgAnswer))
    );
  };

  const renderQuestion = (data) => {
    return (
      <div
        className={`ielts-wrapper-content ${
          darkMode == "dark" ? "dark-mode" : " light-mode"
        }`}
        key={data?.id}
      >
        <div
          className={`box-content-head ${
            darkMode == "dark"
              ? " dark-mode color-white"
              : "light-mode color-black"
          }`}
        >
          <p
            className="title"
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></p>
          <p className="detail">{data.request}</p>
        </div>
        <div
          className={
            darkMode == "light"
              ? "box-content color-black"
              : "box-content color-white"
          }
        >
          {data.questionExams?.map((e) => {
            // console.log(e)
            if (e.questionType === "FillTheBlank") {
              return (
                <WriteAnswer data={e} key={e.id} handleAnswer={handleAnswer} />
              );
            }
            if (e.questionType === "SingleChoice") {
              if (!e.displayType || e.displayType == "Dropdown") {
                return (
                  <QuestionSelectAnswer
                    data={e}
                    key={e.id}
                    handleAnswer={handleAnswer}
                  />
                );
              } else if (e.displayType === "List") {
                return (
                  <SingleChoice
                    key={e.id}
                    data={e}
                    answerHandle={handleAnswer}
                  />
                );
              } else {
              }
            }
            if (e.questionType === "MultipleChoice") {
              return (
                <MultipleChoice
                  data={e}
                  key={e.id}
                  handleAnswer={handleAnswer}
                />
              );
            }
          })}
        </div>
      </div>
    );
  };
  const getData = () => {
    const newdata = JSON.parse(localStorage.getItem("exam"))?.questionContent;
    // const newdata = JSON.parse(questionContent)
    // console.log(newdata)

    let content = newdata?.languageSkillExams?.find(
      (e) => e.languageSkillType === "Listening"
    );

    setData(content);

    let arrQuestion = [];
    content?.partitionExams.map((t) => {
      t.sectionExams?.map((e) => {
        e.groupQuestionExams?.map((i) => {
          i.questionExams?.map((j) => {
            let newPoint = j.points ?? 1;
            for (let index = 0; index < newPoint; index++) {
              arrQuestion.push(j.id);
            }
          });
        });
      });
    });

    setQuestions(arrQuestion);
  };
  useEffect(() => {
    // console.log(localStorage.getItem('exam'))
    // if(!refData.current) {
    getData();
    // }
  }, []);
  // console.log(data)
  return (
    <>
      <div id="listening" className="listening">
        <div className="justify-content-center align-items-center ">
          <div className="container-fluid wrapper-content">
            <div className="container ">
              <div className="col-md-6">
                {data?.partitionExams?.map((t) => {
                  // console.log(t)
                  return (
                    <div key={t?.id}>
                      {t.sectionExams?.map((e) => {
                        // console.log('e-',e)
                        return (
                          <div key={e.id}>
                            <div className="ielts-wrapper-head">
                              {}
                              <p
                                className={
                                  darkMode == "light"
                                    ? "title color-3060CF"
                                    : "title color-00F0FF"
                                }
                                dangerouslySetInnerHTML={{
                                  __html: e.description,
                                }}
                              ></p>
                              {t.materials?.map((tj) => {
                                if (tj.materialType === "mp3")
                                  return (
                                    <div key={tj.id} className="question-audio">
                                      <audio
                                        className="w-100"
                                        src={
                                          process.env.REACT_APP_BE_HOST + tj.url
                                        }
                                        controls="controls"
                                      ></audio>
                                    </div>
                                  );
                              })}
                              {e.materials?.map((j) => {
                                if (j.materialType === "mp3")
                                  return (
                                    <div key={j.id} className="question-audio">
                                      <audio
                                        className="w-100"
                                        src={
                                          process.env.REACT_APP_BE_HOST + j.url
                                        }
                                        controls="controls"
                                      ></audio>
                                    </div>
                                  );
                              })}
                              {skill === "/fulltest" && (
                                <textarea
                                  className="notepad"
                                  placeholder="Your draft goes here..."
                                ></textarea>
                              )}
                            </div>
                            {e.groupQuestionExams?.map((i) => {
                              return renderQuestion(i);
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <SubmitArea />
          {/* isDesktop &&
          <div
            className={`ielts-wrapper-footer ${
              darkMode == "light"
                ? "background-white"
                : " background-footer-dark"
            }`}
          >
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-md-8 p-0 d-flex align-items-center h-100">
                  <ul className="list_question p-0 m-0">
                    {totalQuestion.map((e, i) => {
                      return (
                        <li
                          className={
                            listAnswered.get(e)
                              ? darkMode == "light"
                                ? "answered border-3060CF background-3060CF"
                                : "answered border-00F0FF background-00F0FF"
                              : darkMode == "light"
                              ? "border-black "
                              : " border-white"
                          }
                          key={i}
                        >
                          <a
                            className={
                              darkMode == "light"
                                ? listAnswered.get(e)
                                  ? "color-white"
                                  : "color-black"
                                : "color-white"
                            }
                            href={`#question-${e}`}
                          >
                            {i + 1}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-md-4">
                  <div
                    className={
                      darkMode == "light"
                        ? "row h-50 color-black"
                        : "row h-50 color-white"
                    }
                  >
                    <div
                      className={
                        darkMode == "light"
                          ? "d-flex align-items-end justify-content-center btn-next-skill-light"
                          : "d-flex align-items-end justify-content-center btn-next-skill"
                      }
                      onClick={() => nextSkill(1)}
                    >
                      Go to next skill{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div
                    className={
                      darkMode == "light"
                        ? "row h-50 guide-question color-black "
                        : "row h-50 guide-question color-white"
                    }
                  >
                    <ul className="w-100 d-flex align-items-center justify-content-center m-0">
                      <li>
                        <div
                          className={
                            darkMode == "light"
                              ? "circle border-black"
                              : "circle border-white"
                          }
                        ></div>
                      </li>
                      <li>Unanswered</li>
                      <li>
                        <div
                          className={
                            darkMode == "light"
                              ? "circle circle-answered border-3060CF background-3060CF"
                              : "circle circle-answered border-00F0FF background-00F0FF"
                          }
                        ></div>
                      </li>
                      <li>Answered</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Listening;
